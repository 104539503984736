// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/login/OAuthButtonGroup.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/login/OAuthButtonGroup.tsx");
  import.meta.hot.lastModified = "1708335860440.164";
}
// REMIX HMR END

import { Button, ButtonGroup, Link, VisuallyHidden } from "@chakra-ui/react";
import { FacbookIcon, GoogleIcon, InstagramIcon, TwitterIcon } from "./ProviderIcons";
const providers = [{
  name: "Google",
  icon: <GoogleIcon width={"40px"} height={"40px"} lineHeight={"40px"} />,
  providerName: "google"
}, {
  name: "Twitter",
  icon: <TwitterIcon width={"40px"} height={"40px"} lineHeight={"40px"} />,
  providerName: "twitter"
}, {
  name: "Instagram",
  icon: <InstagramIcon maxW={"none"} width={"40px"} height={"40px"} lineHeight={"40px"} />,
  providerName: "instagram"
}, {
  name: "Facebook",
  icon: <FacbookIcon width={"40px"} height={"40px"} lineHeight={"40px"} />,
  providerName: "facebook"
}];
export const OAuthButtonGroup = () => <ButtonGroup variant="secondary" spacing="4">
    {providers.map(({
    name,
    icon,
    providerName
  }) => <Button key={name} flexGrow={1}>
          <Link href={`${window.ENV.PUBLIC_REACT_APP_BACKEND_URL}/api/connect/${providerName}`}>
          <VisuallyHidden>Anmelden mit {name}</VisuallyHidden>
          {icon}</Link>
        </Button>)}
  </ButtonGroup>;
_c = OAuthButtonGroup;
var _c;
$RefreshReg$(_c, "OAuthButtonGroup");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;