// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/login/PasswordField.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/login/PasswordField.tsx");
  import.meta.hot.lastModified = "1708335860440.164";
}
// REMIX HMR END

import { FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputRightElement, useDisclosure, useMergeRefs } from "@chakra-ui/react";
import { forwardRef, useRef } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
export const PasswordField = _s(forwardRef(_c = _s((props, ref) => {
  _s();
  const {
    isOpen,
    onToggle
  } = useDisclosure();
  const inputRef = useRef(null);
  const mergeRef = useMergeRefs(inputRef, ref);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({
        preventScroll: true
      });
    }
  };
  return <FormControl isInvalid={!!props.error} isRequired={props.isRequired}>
        <FormLabel htmlFor="password">{props.passwordtitle ? props.passwordtitle : "Passwort"}</FormLabel>
        <InputGroup>
          <InputRightElement>
            <IconButton variant="text" aria-label={isOpen ? "Verstecken" : "Anzeigen"} icon={isOpen ? <HiEyeOff /> : <HiEye />} onClick={onClickReveal} />
          </InputRightElement>
          <Input ref={mergeRef} type={isOpen ? "text" : "password"} autoComplete="current-password" required {...props} />
        </InputGroup>
        {props.error && <FormErrorMessage>{props.error}</FormErrorMessage>}
      </FormControl>;
}, "y4C3Qwan9zmlzFaMIdGcqe/ukXk=", false, function () {
  return [useDisclosure, useMergeRefs];
})), "y4C3Qwan9zmlzFaMIdGcqe/ukXk=", false, function () {
  return [useDisclosure, useMergeRefs];
});
_c2 = PasswordField;
PasswordField.displayName = "PasswordField";
var _c, _c2;
$RefreshReg$(_c, "PasswordField$forwardRef");
$RefreshReg$(_c2, "PasswordField");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;